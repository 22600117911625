import { useEffect, useRef } from 'react'
import './OurGamesSection.scss'

import img1 from '../../assets/Game Tiles/JellyRun.png'
import img2 from '../../assets/Game Tiles/ParkingSpace.png'
import img3 from '../../assets/Game Tiles/Unscrew.png'
import img4 from '../../assets/Game Tiles/WaterColorSort.png'

const OurGamesSection = () => {
  const cardsRef = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting)
          entry.target.classList.add("fadeIn")
      })
    }, {
      threshold: 0.5
    })
    observer.observe(cardsRef.current)
  }, [])

  return (
    <section className="ourGamesSection">
      <div className="sectionTitle">
        Our Games
      </div>
      <div className="reasonCards" ref={cardsRef}>
        <div className="card">
          <div className="cardSymbol">
            <div className='imageDiv'>
            <img className='gameImage' src={img1} alt="game 1"/></div>
          </div>
        </div>
        <div className="card">
          <div className="cardSymbol">
            <div className='imageDiv'>
            <img className='gameImage' src={img2} alt="game 1"/></div>
          </div>
        </div>
        <div className="card">
          <div className="cardSymbol">
            <div className='imageDiv'>
            <img className='gameImage' src={img3} alt="game 1"/></div>
          </div>
        </div>
        <div className="card">
          <div className="cardSymbol">
            <div className='imageDiv'>
            <img className='gameImage' src={img4} alt="game 1"/></div>
          </div>
        </div>        
      </div>
      <div className='moreBtn'>
        <p className='moreText'>more...</p>
      </div>
    </section>
  )
}
export default OurGamesSection
