import React from "react";
import './PrivacyPolicyPage.css';

const PrivacyPolicyPage = () =>{
    return(
        <div className="policyContainer">
            <div className="secDiv">
                <p className="sectionTitle">SGR - PRIVACY POLICY</p>
                <p className="sectionContent">
                We at “SGR Creatives – Engaging Casual Games for Everyone” have created this Privacy Policy to demonstrate our commitment to your privacy. The following reveals our information gathering and dissemination practices. By accessing and/or using “engagingcasualgames4all.in” website you are accepting the practices described in this privacy policy.
                </p>
            </div>

            <div className="secDiv">
            <h2 className="sectionsubTitle">AGREEMENT</h2>
            <p className="sectionContent">By using / installing the “SGR Creatives – Engaging Casual Games for Everyone” games or services on your devices is stated that, you agree the terms and conditions detailed in these privacy policies, including what, when, why collecting user’s personal and non – personal information.
            </p>

            <div className="dataCollectionInfoSection">
            <p className="sectionContent">Here we collect the information from the user in three 
                different methods:
            </p>

            <p className="sectionContent"><span>Non – personal information:</span> Third parties will automatically identify the time when you launch or open or using a “SGR Creatives – Engaging Casual Games for Everyone” games, at that time they may collect the user information without limitations, we integrate SDK of third parties in our apps / games to fetch the data which helps to provide appropriate services to the users. Cookies and local storage may also be stored on your device via information gathering technologies used within the “SGR Creatives – Engaging Casual Games for Everyone”.
            </p>

            <p className="sectionContent">
            <span>Personal information:</span> When you are using a “SGR Creatives – Engaging Casual Games for Everyone” games, third parties will automatically collect the user details with the help of SDK integrated in our apps and games.
            </p>

            <p className="sectionContent">
            <span>User provided information:</span> We collect the information voluntarily given by the user, such as feedback, signup (when user wants to create account), Facebook login, and other information asked to fill before or after the use of services provided by “SGR Creatives – Engaging Casual Games for Everyone”.
            </p>
            </div>

            <div className="otherdetailsInfoSection">
                <p className="sectionContent">
                We may share users personal information to third parties if there is a high priority, please find the following cases where we encounter with high priority of data sharing:
                </p>

                <ul className="sectionContentList">
                    <li className="sectionListItem">
                    To identify, eliminate, or otherwise address fraud, security or technical issues;
                    </li>
                    <li className="sectionListItem">
                        To respond to claims content violation of any right of a third party;
                    </li>
                    <li className="sectionListItem">
                    To obey any applicable law, regulation, legal process, subpoena or governmental request;
                    </li>
                    <li className="sectionListItem">
                    To enforce this Privacy Policy and/or the terms of use and other “Engaging Casual Games For All People” policies, including investigation of potential violations thereof;
                    </li>
                    <li className="sectionListItem">
                        To protect the personal safety, property, or rights of the Company, its users or the general public;
                    </li>
                    <li className="sectionListItem">
                    Change took place in the ownership, merger with others, acquisition or purchase of all or substantially all of the assets of Company;
                    </li>
                    <li className="sectionListItem">
                    To let our third parties, affiliates and partners to deliver with advertisements, support or services;
                    </li>
                    <li className="sectionListItem">
                    As part of a competition, receiving rewards or otherwise interact with our social media channels;
                    </li>
                </ul>
            </div>
            <div className="noteSection">
                <p className="sectionContent">
                <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                </p>
            </div>
                
            </div>
            <div className="secDiv">
                <h2 className="sectionsubTitle">ADVERTISEMENTS:</h2>
                <p className="sectionContent">Apps and Games of SGR include various types of advertisements, such as banners, interstitials, rewarded videos, native ads, etc. from third parties ad networks. Third parties may collect and use the following information of users:</p>
                <ul className="sectionContentList">
                    <li className="sectionListItem">
                    Geographic location tracking
                    </li>
                    <li className="sectionListItem">
                    Network preferences
                    </li>
                    <li className="sectionListItem">
                    Clicks, impressions, views of advertisements
                    </li>
                    <li className="sectionListItem">
                    Personal information such as, age, gender, etc…
                    </li>
                </ul>
                <p className="sectionContent">Advertisements shown to the user will be based on the above mentioned points and also IP address, device identifiers and other Personal Information gathered during the use of SGR’s apps and games. Third party ad networks which are collaborated with the SGR games are listed here: Ad mob, Facebook, Inmobi, Chartboost, Mopub, Unity, Vungle, AdColony, and more.. The following third parties may collect the user personal / non – personal information when user clicks on the advertisement displayed by them. The privacy policy will not be same for all, kindly please review the terms of use and privacy policy for any advertiser before doing business with them. Using services of advertiser is at your own risk.</p>
            </div>
            <div className="secDiv">
                <h2 className="sectionsubTitle">USING GAME CENTER & SOCIAL FEATURES:</h2>
                <p className="sectionContent">Game center features and social features in the apps and games of SGR are controlled or allow for social integration with certain third party social networks. While using the game center features and social features, user should take a brief look on terms of use and privacy policy of the respective third party service providers such as, platforms like Apple Game Center, Google Play Game Center and Windows Game Center services. To know the use of your information collected through the above mention services, you should go through their terms of use and privacy policies. If you disagree their terms and conditions, you may not use all the features included in the SGR apps and games. You understand that where you agree with any third party Social Network or Platform, we may share data about you with your contacts in order that you may enjoy social elements. This data may include, without limitation, your scores and/or progress in our SGR apps and games, your name; your social media profile picture and any test that you may upload.</p>
                <p className="sectionContent">These social networks or platforms may collect the personal information, such as your IP address, non-personal Information and may set cookies and other web tracking technologies to enable the social features and the game center features. The use of these services leads to share your information with your friends or public, depends on the features provided by the third parties. Using the above services will considered as the acceptance of terms and conditions of service provides, when you agree those policies and share the content via Social Networks or Platforms that your content may become public and any person may re-post-it.</p>
            </div>
            <div className="secDiv">
                <h2 className="sectionsubTitle">OPTING OUT:</h2>
                <p className="sectionContent">By considering all the user comforts, we SGR integrated on / off controls based on the app or game where user can choose to remove or stop or resume the provided functionalities of any app or game developed or published by the SGR Studios Pvt Ltd. Users contact information is used by SGR under the act of Right to Information act 2005 by INDIA, the contact information is only used to send an important information such as, legal information if necessary, user account information and if any changes or updates took place in this privacy policy.</p>
                <p className="sectionContent">The following instructions help explain how you may use your mobile device controls to revoke your consent to limit the use of the device identifier for advertising purposes.</p>
                <p className="sectionContent"><span>Google Android – Limit AdvertisingGoogle Android – Limit Advertising</span></p>
                <ul className="sectionContentList">
                    <li className="sectionListItem">
                    Open the Google Settings on your device
                    </li>
                    <li className="sectionListItem">
                    Select Ads
                    </li>
                    <li className="sectionListItem">
                    Select Opt Out of Ads Personalization
                    </li>
                </ul>
                <p className="sectionContent"><span>Apple iOS – Limit Advertising</span></p>
                <ul className="sectionContentList">
                    <li className="sectionListItem">
                    Open the Settings on your device
                    </li>
                    <li className="sectionListItem">
                    Select Privacy
                    </li>
                    <li className="sectionListItem">
                    Select Advertising
                    </li>
                    <li className="sectionListItem">
                    Select Limit Ad Tracking
                    </li>
                </ul>
            </div>

            <div className="secDiv">
            <h2 className="sectionsubTitle">CHANGES TO THE PRIVACY POLICY</h2>
            <p className="sectionContent">This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here and we are not responsible for informing you about changes, you should keep notice by yourself. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p>
            <p className="sectionContent">
            If there are any questions regarding this privacy policy you may contact us here: sgrcreatives@gmail.com and we will make an effort to reply within a reasonable timeframe.If there are any questions regarding this privacy policy you may contact us here: sgrcreatives@gmail.com and we will make an effort to reply within a reasonable timeframe.
            </p>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;